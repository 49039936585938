.footer {
	background-color: var(--background-variant);
	padding: var(--spacing-large);
	padding-left: var(--spacing-site-left);

	display: flex;
	gap: var(--spacing-large);
}

.footer__link {
	color: var(--text-color);
}