/*
	ResizeObserver doesn't detect scrollbar appearance,
	below is made to make sure the scrollbar is on by default
*/
#root { min-height: 100vh }

.body {
	margin: 0;
	font-family: 'barlow', sans-serif;
	background-color: var(--background);
	color: var(--text-color) ;
	overflow-x: hidden;
}

.main-content {
	padding: calc(3 * var(--spacing-large)) 0;
	width: 75%;
	margin: auto;
	min-height: calc(100vh - 21rem);
}

@media screen and (max-width: 80rem){
	.main-content {
		padding: calc(3 * var(--spacing-large)) 0;
		width: 90%;
		margin: auto;
	}
}
